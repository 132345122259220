import React from 'react'

import Layout from './layout'
import Image from './image'
import SEO from './seo'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

const ShowPage = ({ pageContext: { toc, title, content } }) => {
  return (
    <Layout navPages={toc}>
      <SEO title={title} />
      <Grid container spacing={3} justify="center">
        <h1>{title}</h1>
        <Divider />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Grid>
      <Divider />
    </Layout>
  )
}

export default ShowPage
